import { ENDPOINT, PRODUCTION } from '@server/lib/config';

export const BETA_DATA = {
  '49': {
    id: 49,
    value: 'Thiên Long',
    position: 8,
    is_default: 0,
    slug: 'thien-long',
  },
  '65': { id: 65, value: 'Deli', position: 6, is_default: 0, slug: 'deli' },
  '66': { id: 66, value: 'AAA', position: 4, is_default: 0, slug: 'aaa' },
  '67': { id: 67, value: 'Uni', position: 5, is_default: 0, slug: 'uni' },
  '68': {
    id: 68,
    value: 'ClearBook',
    position: 7,
    is_default: 0,
    slug: 'clearbook',
  },
  '69': {
    id: 69,
    value: 'KingJim',
    position: 9,
    is_default: 0,
    slug: 'kingjim',
  },
  '70': { id: 70, value: 'HL03', position: 7, is_default: 0, slug: 'hl03' },
  '71': {
    id: 71,
    value: 'Artline',
    position: 8,
    is_default: 0,
    slug: 'artline',
  },
  '72': { id: 72, value: 'BAOKE', position: 9, is_default: 0, slug: 'baoke' },
  '73': { id: 73, value: ' HS', position: 10, is_default: 0, slug: '-hs' },
  '74': {
    id: 74,
    value: 'Shiny',
    position: 11,
    is_default: 0,
    slug: 'shiny',
  },
  '75': {
    id: 75,
    value: 'HORSE',
    position: 12,
    is_default: 0,
    slug: 'horse',
  },
  '81': { id: 81, value: 'Tomy', position: 13, is_default: 0, slug: 'tomy' },
};

export const PROD_DATA = {
  '49': {
    id: 49,
    value: 'Thiên Long',
    position: 5,
    is_default: 0,
    slug: 'thien-long',
    title:
      'Văn Phòng Phẩm Thiên Long Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Thiên Long Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Thiên Long Chất lượng, giá tốt tại TPHCM',
  },
  '71': {
    id: 71,
    value: 'Deli',
    position: 3,
    is_default: 0,
    slug: 'deli',
    title: 'Văn Phòng Phẩm Deli Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Deli Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Deli Chất lượng, giá tốt tại TPHCM',
  },
  '72': {
    id: 72,
    value: 'ClearBook',
    position: 4,
    is_default: 0,
    slug: 'clearbook',
    title: 'Bìa Nhựa, Bìa Lá ClearBook Chính hãng TPHCM',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm ClearBook Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm ClearBook Chất lượng, giá tốt tại TPHCM',
  },
  '73': {
    id: 73,
    value: 'KingJim',
    position: 6,
    is_default: 0,
    slug: 'kingjim',
    title: 'Văn Phòng Phẩm KingJim Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm KingJim Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm KingJim Chất lượng, giá tốt tại TPHCM',
  },
  '74': {
    id: 74,
    value: 'KingStar',
    position: 7,
    is_default: 0,
    slug: 'kingstar',
    title:
      'Văn Phòng Phẩm KingStar Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm KingStar Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm KingStar Chất lượng, giá tốt tại TPHCM',
  },
  '75': {
    id: 75,
    value: 'Plus',
    position: 8,
    is_default: 0,
    slug: 'plus',
    title: 'Văn Phòng Phẩm Plus Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Plus Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Plus Chất lượng, giá tốt tại TPHCM',
  },
  '76': {
    id: 76,
    value: 'Uniball',
    position: 7,
    is_default: 0,
    slug: 'uniball',
    title: 'Văn Phòng Phẩm Uniball Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Uniball Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Uniball Chất lượng, giá tốt tại TPHCM',
  },
  '77': {
    id: 77,
    value: 'Aihao',
    position: 39,
    is_default: 0,
    slug: 'aihao',
    title: 'Văn Phòng Phẩm Aihao Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Aihao Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Aihao Chất lượng, giá tốt tại TPHCM',
  },
  '78': {
    id: 78,
    value: 'Bến Nghé',
    position: 9,
    is_default: 0,
    slug: 'ben-nghe',
    title:
      'Văn Phòng Phẩm Bến Nghé Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Bến Nghé Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Bến Nghé Chất lượng, giá tốt tại TPHCM',
  },
  '79': {
    id: 79,
    value: 'HORSE',
    position: 10,
    is_default: 0,
    slug: 'horse',
    title: 'Văn Phòng Phẩm HORSE Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HORSE Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HORSE Chất lượng, giá tốt tại TPHCM',
  },
  '80': {
    id: 80,
    value: 'Shiny',
    position: 11,
    is_default: 0,
    slug: 'shiny',
    title: 'Văn Phòng Phẩm Shiny Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Shiny Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Shiny Chất lượng, giá tốt tại TPHCM',
  },
  '81': {
    id: 81,
    value: 'Zebra',
    position: 12,
    is_default: 0,
    slug: 'zebra',
    title: 'Văn Phòng Phẩm Zebra Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Zebra Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Zebra Chất lượng, giá tốt tại TPHCM',
  },
  '84': {
    id: 84,
    value: 'HL03',
    position: 15,
    is_default: 0,
    slug: 'hl03',
    title: 'Văn Phòng Phẩm HL03 Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HL03 Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HL03 Chất lượng, giá tốt tại TPHCM',
  },
  '85': {
    id: 85,
    value: 'IKPLUS',
    position: 16,
    is_default: 0,
    slug: 'ikplus',
    title: 'Văn Phòng Phẩm IKPLUS Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IKPLUS Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IKPLUS Chất lượng, giá tốt tại TPHCM',
  },
  '86': {
    id: 86,
    value: 'Excel',
    position: 17,
    is_default: 0,
    slug: 'excel',
    title: 'Văn Phòng Phẩm Excel Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Excel Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Excel Chất lượng, giá tốt tại TPHCM',
  },
  '87': {
    id: 87,
    value: 'Double A',
    position: 18,
    is_default: 0,
    slug: 'double-a',
    title:
      'Văn Phòng Phẩm Double A Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Double A Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Double A Chất lượng, giá tốt tại TPHCM',
  },
  '88': {
    id: 88,
    value: 'Pronoti',
    position: 19,
    is_default: 0,
    slug: 'pronoti',
    title: 'Văn Phòng Phẩm Pronoti Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pronoti Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pronoti Chất lượng, giá tốt tại TPHCM',
  },
  '89': {
    id: 89,
    value: 'Gateway',
    position: 20,
    is_default: 0,
    slug: 'gateway',
    title: 'Văn Phòng Phẩm Gateway Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Gateway Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Gateway Chất lượng, giá tốt tại TPHCM',
  },
  '90': {
    id: 90,
    value: 'IK Plus',
    position: 8,
    is_default: 0,
    slug: 'ik-plus',
    title: 'Văn Phòng Phẩm IK Plus Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IK Plus Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IK Plus Chất lượng, giá tốt tại TPHCM',
  },
  '91': {
    id: 91,
    value: 'Subaru',
    position: 21,
    is_default: 0,
    slug: 'subaru',
    title: 'Văn Phòng Phẩm Subaru Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Subaru Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Subaru Chất lượng, giá tốt tại TPHCM',
  },
  '92': {
    id: 92,
    value: 'Emerald',
    position: 22,
    is_default: 0,
    slug: 'emerald',
    title: 'Văn Phòng Phẩm Emerald Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Emerald Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Emerald Chất lượng, giá tốt tại TPHCM',
  },
  '93': {
    id: 93,
    value: 'Primary',
    position: 23,
    is_default: 0,
    slug: 'primary',
    title: 'Văn Phòng Phẩm Primary Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Primary Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Primary Chất lượng, giá tốt tại TPHCM',
  },
  '94': {
    id: 94,
    value: 'IK Natural',
    position: 8,
    is_default: 0,
    slug: 'ik-natural',
    title:
      'Văn Phòng Phẩm IK Natural Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IK Natural Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm IK Natural Chất lượng, giá tốt tại TPHCM',
  },
  '95': {
    id: 95,
    value: 'Paper One',
    position: 16,
    is_default: 0,
    slug: 'paper-one',
    title:
      'Văn Phòng Phẩm Paper One Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Paper One Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Paper One Chất lượng, giá tốt tại TPHCM',
  },
  '96': {
    id: 96,
    value: 'Tomy',
    position: 24,
    is_default: 0,
    slug: 'tomy',
    title: 'Văn Phòng Phẩm Tomy Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Tomy Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Tomy Chất lượng, giá tốt tại TPHCM',
  },
  '97': {
    id: 97,
    value: 'Liên Sơn',
    position: 25,
    is_default: 0,
    slug: 'lien-son',
    title:
      'Văn Phòng Phẩm Liên Sơn Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Liên Sơn Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Liên Sơn Chất lượng, giá tốt tại TPHCM',
  },
  '98': {
    id: 98,
    value: 'Supreme',
    position: 26,
    is_default: 0,
    slug: 'supreme',
    title: 'Văn Phòng Phẩm Supreme Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Supreme Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Supreme Chất lượng, giá tốt tại TPHCM',
  },
  '99': {
    id: 99,
    value: 'Post-it',
    position: 27,
    is_default: 0,
    slug: 'post-it',
    title: 'Văn Phòng Phẩm Post-it Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Post-it Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Post-it Chất lượng, giá tốt tại TPHCM',
  },
  '100': {
    id: 100,
    value: 'UNC',
    position: 28,
    is_default: 0,
    slug: 'unc',
    title: 'Văn Phòng Phẩm UNC Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm UNC Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm UNC Chất lượng, giá tốt tại TPHCM',
  },
  '102': {
    id: 102,
    value: 'Kokuyo',
    position: 30,
    is_default: 0,
    slug: 'kokuyo',
    title: 'Văn Phòng Phẩm Kokuyo Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Kokuyo Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Kokuyo Chất lượng, giá tốt tại TPHCM',
  },
  '103': {
    id: 103,
    value: 'Stickiii',
    position: 31,
    is_default: 0,
    slug: 'stickiii',
    title:
      'Văn Phòng Phẩm Stickiii Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Stickiii Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Stickiii Chất lượng, giá tốt tại TPHCM',
  },
  '104': {
    id: 104,
    value: 'Sitck It On',
    position: 32,
    is_default: 0,
    slug: 'sitck-it-on',
    title:
      'Văn Phòng Phẩm Sitck It On Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Sitck It On Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Sitck It On Chất lượng, giá tốt tại TPHCM',
  },
  '105': {
    id: 105,
    value: 'Idea',
    position: 33,
    is_default: 0,
    slug: 'idea',
    title: 'Văn Phòng Phẩm Idea Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Idea Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Idea Chất lượng, giá tốt tại TPHCM',
  },
  '106': {
    id: 106,
    value: 'Quality',
    position: 34,
    is_default: 0,
    slug: 'quality',
    title: 'Văn Phòng Phẩm Quality Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Quality Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Quality Chất lượng, giá tốt tại TPHCM',
  },
  '107': {
    id: 107,
    value: 'Pelure Blanc',
    position: 35,
    is_default: 0,
    slug: 'pelure-blanc',
    title:
      'Văn Phòng Phẩm Pelure Blanc Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pelure Blanc Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pelure Blanc Chất lượng, giá tốt tại TPHCM',
  },
  '108': {
    id: 108,
    value: 'G-star',
    position: 36,
    is_default: 0,
    slug: 'g-star',
    title: 'Văn Phòng Phẩm G-star Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm G-star Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm G-star Chất lượng, giá tốt tại TPHCM',
  },
  '109': {
    id: 109,
    value: 'Kokusai',
    position: 37,
    is_default: 0,
    slug: 'kokusai',
    title: 'Văn Phòng Phẩm Kokusai Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Kokusai Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Kokusai Chất lượng, giá tốt tại TPHCM',
  },
  '110': {
    id: 110,
    value: 'TOYO',
    position: 38,
    is_default: 0,
    slug: 'toyo',
    title: 'Văn Phòng Phẩm TOYO Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TOYO Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TOYO Chất lượng, giá tốt tại TPHCM',
  },
  '111': {
    id: 111,
    value: 'Halo.zee',
    position: 40,
    is_default: 0,
    slug: 'halozee',
    title:
      'Văn Phòng Phẩm Halo.zee Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Halo.zee Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Halo.zee Chất lượng, giá tốt tại TPHCM',
  },
  '112': {
    id: 112,
    value: 'TIZO',
    position: 41,
    is_default: 0,
    slug: 'tizo',
    title: 'Văn Phòng Phẩm TIZO Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TIZO Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TIZO Chất lượng, giá tốt tại TPHCM',
  },
  '113': {
    id: 113,
    value: 'Pilot',
    position: 42,
    is_default: 0,
    slug: 'pilot',
    title: 'Văn Phòng Phẩm Pilot Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pilot Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pilot Chất lượng, giá tốt tại TPHCM',
  },
  '114': {
    id: 114,
    value: 'M&G',
    position: 43,
    is_default: 0,
    slug: 'mg',
    title: 'Văn Phòng Phẩm M&G Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm M&G Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm M&G Chất lượng, giá tốt tại TPHCM',
  },
  '115': {
    id: 115,
    value: 'Tritouch Double A',
    position: 44,
    is_default: 0,
    slug: 'tritouch-double-a',
    title:
      'Văn Phòng Phẩm Tritouch Double A Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Tritouch Double A Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Tritouch Double A Chất lượng, giá tốt tại TPHCM',
  },
  '116': {
    id: 116,
    value: 'Penta Việt Hoa',
    position: 45,
    is_default: 0,
    slug: 'penta-viet-hoa',
    title:
      'Văn Phòng Phẩm Penta Việt Hoa Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Penta Việt Hoa Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Penta Việt Hoa Chất lượng, giá tốt tại TPHCM',
  },
  '117': {
    id: 117,
    value: 'Đông A',
    position: 46,
    is_default: 0,
    slug: 'dong-a',
    title: 'Văn Phòng Phẩm Đông A Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Đông A Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Đông A Chất lượng, giá tốt tại TPHCM',
  },
  '118': {
    id: 118,
    value: 'LINC',
    position: 47,
    is_default: 0,
    slug: 'linc',
    title: 'Văn Phòng Phẩm LINC Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm LINC Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm LINC Chất lượng, giá tốt tại TPHCM',
  },
  '119': {
    id: 119,
    value: 'Queen',
    position: 48,
    is_default: 0,
    slug: 'queen',
    title: 'Văn Phòng Phẩm Queen Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Queen Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Queen Chất lượng, giá tốt tại TPHCM',
  },
  '120': {
    id: 120,
    value: 'HADA',
    position: 49,
    is_default: 0,
    slug: 'hada',
    title: 'Văn Phòng Phẩm HADA Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HADA Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HADA Chất lượng, giá tốt tại TPHCM',
  },
  '121': {
    id: 121,
    value: 'Staedtler',
    position: 50,
    is_default: 0,
    slug: 'staedtler',
    title:
      'Văn Phòng Phẩm Staedtler Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Staedtler Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Staedtler Chất lượng, giá tốt tại TPHCM',
  },
  '122': {
    id: 122,
    value: 'Marvy',
    position: 51,
    is_default: 0,
    slug: 'marvy',
    title: 'Văn Phòng Phẩm Marvy Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Marvy Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Marvy Chất lượng, giá tốt tại TPHCM',
  },
  '123': {
    id: 123,
    value: 'Toppoint',
    position: 52,
    is_default: 0,
    slug: 'toppoint',
    title:
      'Văn Phòng Phẩm Toppoint Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Toppoint Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Toppoint Chất lượng, giá tốt tại TPHCM',
  },
  '124': {
    id: 124,
    value: 'Stabilo',
    position: 53,
    is_default: 0,
    slug: 'stabilo',
    title: 'Văn Phòng Phẩm Stabilo Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Stabilo Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Stabilo Chất lượng, giá tốt tại TPHCM',
  },
  '125': {
    id: 125,
    value: 'Jellitto',
    position: 54,
    is_default: 0,
    slug: 'jellitto',
    title:
      'Văn Phòng Phẩm Jellitto Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Jellitto Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Jellitto Chất lượng, giá tốt tại TPHCM',
  },
  '126': {
    id: 126,
    value: 'STACOM',
    position: 55,
    is_default: 0,
    slug: 'stacom',
    title: 'Văn Phòng Phẩm STACOM Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm STACOM Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm STACOM Chất lượng, giá tốt tại TPHCM',
  },
  '127': {
    id: 127,
    value: 'Monami',
    position: 56,
    is_default: 0,
    slug: 'monami',
    title: 'Văn Phòng Phẩm Monami Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Monami Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Monami Chất lượng, giá tốt tại TPHCM',
  },
  '128': {
    id: 128,
    value: 'Pentel',
    position: 57,
    is_default: 0,
    slug: 'pentel',
    title: 'Văn Phòng Phẩm Pentel Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pentel Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Pentel Chất lượng, giá tốt tại TPHCM',
  },
  '129': {
    id: 129,
    value: 'Xukiva',
    position: 58,
    is_default: 0,
    slug: 'xukiva',
    title: 'Văn Phòng Phẩm Xukiva Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Xukiva Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Xukiva Chất lượng, giá tốt tại TPHCM',
  },
  '130': {
    id: 130,
    value: 'Suremark',
    position: 59,
    is_default: 0,
    slug: 'suremark',
    title:
      'Văn Phòng Phẩm Suremark Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Suremark Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Suremark Chất lượng, giá tốt tại TPHCM',
  },
  '131': {
    id: 131,
    value: 'Bizner',
    position: 60,
    is_default: 0,
    slug: 'bizner',
    title: 'Văn Phòng Phẩm Bizner Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Bizner Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Bizner Chất lượng, giá tốt tại TPHCM',
  },
  '132': {
    id: 132,
    value: 'UNI',
    position: 61,
    is_default: 0,
    slug: 'uni',
    title: 'Văn Phòng Phẩm UNI Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm UNI Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm UNI Chất lượng, giá tốt tại TPHCM',
  },
  '133': {
    id: 133,
    value: 'TTM',
    position: 62,
    is_default: 0,
    slug: 'ttm',
    title: 'Văn Phòng Phẩm TTM Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TTM Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm TTM Chất lượng, giá tốt tại TPHCM',
  },
  '134': {
    id: 134,
    value: 'INK MARKER',
    position: 63,
    is_default: 0,
    slug: 'ink-marker',
    title:
      'Văn Phòng Phẩm INK MARKER Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm INK MARKER Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm INK MARKER Chất lượng, giá tốt tại TPHCM',
  },
  '135': {
    id: 135,
    value: 'Digno',
    position: 64,
    is_default: 0,
    slug: 'digno',
    title: 'Văn Phòng Phẩm Digno Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Digno Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Digno Chất lượng, giá tốt tại TPHCM',
  },
  '136': {
    id: 136,
    value: 'Frixion',
    position: 65,
    is_default: 0,
    slug: 'frixion',
    title: 'Văn Phòng Phẩm Frixion Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Frixion Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm Frixion Chất lượng, giá tốt tại TPHCM',
  },
  '137': {
    id: 137,
    value: 'HS',
    position: 66,
    is_default: 0,
    slug: 'hs',
    title: 'Văn Phòng Phẩm HS Chính hãng, Ship Siêu Nhanh, Giá Cạnh Tranh',
    description:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HS Chất lượng, giá tốt tại TPHCM',
    longDescription:
      'Fast.vn Đơn Vị chuyên cung cấp các sản phẩm văn phòng phẩm HS Chất lượng, giá tốt tại TPHCM',
  },
};

export default ENDPOINT == PRODUCTION ? PROD_DATA : BETA_DATA;
